// import React, { useEffect, useContext } from "react"
// import Spinner from "../components/Spinner"
// import DashboardLayout from "../components/DashboardLayout/"
// import PrivateRoute from "../components/Routes/PrivateRoute"
// import AuthContext from "../context/auth/authContext"
// import { navigate } from "gatsby"

// const Dashboard = () => {
//   const { loading, loadUser, user } = useContext(AuthContext)

//   useEffect(() => {
//     user === null && loadUser()
//   }, [])

//   //   if (typeof window !== 'undefined') {
//   //   if (!localStorage.getItem("token")) {
//   //     navigate("/user/login")
//   //     return null
//   //   } else {
//   //     return loading || user === null ? (
//   //       <Skeleton variant="rect" width="100%" height="100vh" />
//   //     ) : (
//   //       <DashboardLayout />
//   //     )
//   //   }
//   // }

// 

// export default Dashboard

import React, { useEffect, useContext } from "react"
import { Router } from "@reach/router"
import DashboardLayout from "../components/DashboardLayout/"
import MainDashboard from "../components/DashboardContent/MainDashboard"
import Profile from "../components/DashboardContent/Profile"
import Refer from "../components/DashboardContent/Refer"
import Spinner from "../components/Spinner"
import { navigate } from "gatsby"
import AuthContext from "../context/auth/authContext"



const Dashboard = () => {

  const { loading, loadUser, user } = useContext(AuthContext)
    useEffect(() => {
    user === null && loadUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (typeof window !== "undefined") {
        if (!localStorage.getItem("token")) {
          navigate("/user/login")
          return null
        } else if (loading || user === null) {
          // return <Skeleton variant="rect" width="100%" height="100vh" />
          return <Spinner size={70} />
        } else {
          return (
            <DashboardLayout>
              <Router basepath="/dashboard">
                <Profile path="/profile"  />
                <Refer path="/refer-a-friend" />
                <MainDashboard path="/" />
              </Router>
            </DashboardLayout>
          )
        }
      } else {
        return null
      }
    }

  

export default Dashboard
