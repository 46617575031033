import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
  },
  formControl: {
      width: "100%"
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: ".3rem",
  },
  shareIcons: {
    justifyContent: "space-between",
      display: "flex",
  },
  shareLink: {
    padding: "0.5rem 1rem",
    border: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    color: theme.palette.primary.main,
    justifyContent: "space-between",
    borderRadius: "5px",
    backgroundColor: "#E8EFFD",
    whiteSpace: "normal",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem"
    },
  },
 referLink: {
  fontSize: "14px",
  fontWeight: "bold"
 },
 action: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },

}))

export { useStyles }