import React, { useContext } from "react"
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
} from "@material-ui/core"
import SocialShare from "../../../components/SocialShare"
import { Formik, Form, ErrorMessage, FieldArray, Field } from "formik"
import { useTheme } from "@material-ui/styles"
import FormErrors from "../../FormErrors"
import * as Yup from "yup"
import Paper from "../../Paper"
import { useStyles } from "./style"

import Button from "../../Button"
import { Close, Add } from "@material-ui/icons"

import LoanContext from "../../../context/loan/loanContext"
import AlertContext from "../../../context/alert/alertContext"
import AuthContext from "../../../context/auth/authContext"

const Refer = () => {
  const { isLoading, refer } = useContext(LoanContext)
  const { setAlert } = useContext(AlertContext)
  const {
    user: { id, lastName, firstName, email },
  } = useContext(AuthContext)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const message = `I just applied for a loan to finance my education with this cool new platform, Bursery. They offer a relatively low interest rate and application was super quick and easy. You should check it out. Visit https://bursery.com for more. Cheers`
  const initialValues = { message, friends: [""] }

  const validationSchema = Yup.object({
    message: Yup.string().required("Message is empty"),
  })

  let passed = true
  const onSubmit = async (values, resetForm, initialValues) => {
    values.friends.forEach(friend => {
      if (friend === "" || friend.name === "" || friend.email === "") {
        passed = false
        setAlert("Please fill all fields", "error")
        return false
      }

      if (!/\S+@\S+\.\S+/.test(friend.email)) {
        passed = false
        setAlert("Avoid Invalid email format", "error")
        return false
      }

      //check if user included his/her email as recipient
      if(friend.email === email){
        passed = false
        setAlert("You're not allowed to refer yourself", "error")
        return false
      }
    })

    values = {
      userEmail: email,
      userName: lastName + " " + firstName,
      friends: values.friends,
      message: values.message,
    }
    passed && (await refer(values, resetForm))
  }

  // const shareUrl = `https://bursery.com/refer?ref=${id}`
  const shareUrl = `https://bursery.com?ref=${id}`
  const classes = useStyles()

  return (
    <Box>
      <Typography color="primary" variant="h6" gutterBottom>
        Refer a friend
      </Typography>
      <Paper padding={25} borderRadius={1}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // onSubmit={onSubmit}
          onSubmit={async (initialValues, { resetForm }) => {
           onSubmit(initialValues, resetForm)
          }}
          render={({ values, getFieldProps, resetForm }) => (
            <Form>
              <FormControl className={classes.formControl}>
                <Typography className={classes.label}>Message</Typography>
                <TextField
                  name="message"
                  id="message"
                  fullWidth
                  multiline
                  rows={4}
                  {...getFieldProps("message")}
                  variant="outlined"
                  size="small"
                />
                <ErrorMessage name="message" component={FormErrors} />
              </FormControl>
              <FieldArray
                name="friends"
                render={arrayHelpers => (
                  <div>
                    {values.friends && values.friends.length > 0 ? (
                      <Box marginTop={1}>
                        {values.friends.map((friend, index) => (
                          <Grid
                            container
                            spacing={isMobile ? 2 : 4}
                            key={index}
                          >
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl className={classes.formControl}>
                                <Typography className={classes.label}>
                                  Friend's Name
                                </Typography>

                                <Field
                                  name={`friends[${index}].name`}
                                  style={{
                                    height: "2.5rem",
                                    padding: "0 1rem",
                                  }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl className={classes.formControl}>
                                <Typography className={classes.label}>
                                  Friend's email Address
                                </Typography>
                                <Field
                                  name={`friends[${index}].email`}
                                  style={{
                                    height: "2.5rem",
                                    padding: "0 1rem",
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        ))}
                        <Box marginTop={2}>
                          <Typography
                            display="inline"
                            variant="body2"
                            color="primary"
                            style={{
                              fontSize: "12px",
                              marginTop: "0.5rem",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              arrayHelpers.push({ name: "", email: "" })
                            }
                          >
                            <Add style={{ fontSize: "0.8rem" }} /> Add another
                            friend
                          </Typography>
                          {values.friends.length > 1 && (
                            <Typography
                              display="inline"
                              variant="body2"
                              color="primary"
                              style={{
                                fontSize: "12px",
                                marginLeft: "1rem",
                                cursor: "pointer",
                              }}
                              onClick={() => arrayHelpers.remove(values.friends.length - 1)}
                            >
                              <Close style={{ fontSize: "0.8rem" }} /> Remove
                              friend
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <button
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({ name: "", email: "" })
                        }
                      >
                        Add a friend
                      </button>
                    )}
                    <Box align="right" marginTop={3} className={classes.action}>
                      <Typography
                        style={{fontWeight: "600", marginRight: "1rem", cursor:"pointer"}}
                        onClick={() =>
                          resetForm({
                            initialValues: {
                              message,
                              friends: [""],
                            },
                          })
                        }
                      >
                        Cancel
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Send
                      </Button>
                    </Box>
                  </div>
                )}
              />
            </Form>
          )}
        />
        <SocialShare shareUrl={shareUrl}/>
      </Paper>
    </Box>
  )
}

export default Refer
